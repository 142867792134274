import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'

// import RouteViewComponent from '../layouts/RouterBypass.vue'
import { useUserStore } from '../stores/user-store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'organizations' },
  },
  {
    name: 'admin',
    path: '/',
    component: AppLayout,
    redirect: { name: 'organizations' },
    children: [
      //   {
      //     name: 'dashboard',
      //     path: 'dashboard',
      //     component: () => import('../pages/admin/dashboard/Dashboard.vue'),
      //   },
      // {
      //   name: 'settings',
      //   path: 'settings',
      //   component: () => import('../pages/settings/Settings.vue'),
      // },
      // {
      //   name: 'preferences',
      //   path: 'preferences',
      //   component: () => import('../pages/preferences/Preferences.vue'),
      // },
      {
        name: 'users',
        path: 'users',
        component: () => import('../pages/users/UsersPage.vue'),
      },
      {
        name: 'locations',
        path: 'locations',
        component: () => import('../pages/locations/LocationsPage.vue'),
      },
      {
        name: 'location',
        path: 'location/:location_id',
        component: () => import('../pages/locations/LocationPage.vue'),
      },
      {
        name: 'organizations',
        path: 'organizations',
        component: () => import('../pages/organizations/OrganizationsPage.vue'),
      },
      {
        name: 'organization',
        path: 'organization/:organization_id',
        component: () => import('../pages/organizations/OrganizationPage.vue'),
      },
      {
        name: 'visualizations',
        path: 'visualizations',
        component: () => import('../pages/visualizations/Visualizations.vue'),
      },
      {
        name: 'devices',
        path: 'devices',
        component: () => import('../pages/devices/DevicesPage.vue'),
      },
      // {
      //   name: 'payments',
      //   path: '/payments',
      //   component: RouteViewComponent,
      //   children: [
      //     {
      //       name: 'payment-methods',
      //       path: 'payment-methods',
      //       component: () => import('../pages/payments/PaymentsPage.vue'),
      //     },
      //     {
      //       name: 'billing',
      //       path: 'billing',
      //       component: () => import('../pages/billing/BillingPage.vue'),
      //     },
      //     {
      //       name: 'pricing-plans',
      //       path: 'pricing-plans',
      //       component: () => import('../pages/pricing-plans/PricingPlans.vue'),
      //     },
      //   ],
      // },
      // {
      //   name: 'faq',
      //   path: '/faq',
      //   component: () => import('../pages/faq/FaqPage.vue'),
      // },
    ],
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
      },
      // {
      //   name: 'signup',
      //   path: 'signup',
      //   component: () => import('../pages/auth/Signup.vue'),
      // },
      // {
      //   name: 'recover-password',
      //   path: 'recover-password',
      //   component: () => import('../pages/auth/RecoverPassword.vue'),
      // },
      // {
      //   name: 'recover-password-email',
      //   path: 'recover-password-email',
      //   component: () => import('../pages/auth/CheckTheEmail.vue'),
      // },
      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

router.beforeResolve((to) => {
  const userStore = useUserStore()

  if (to.path === '/auth/login') {
    return
  }

  if (!userStore.user) {
    return '/auth/login'
  }
})

export default router
